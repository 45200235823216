import { differenceInYears, addYears } from 'date-fns';
import { isBefore } from 'date-fns/fp';
import moment from 'moment';
import {
  DateOfBirthIngredients,
  parseDateIngredient,
  validateDate,
} from '../../../helpers/forms/dateHelpers';
import getNextSignificantStartTime from '../../../helpers/timeUtils/getNextSignificantStartTime';

const getUserDateOfBirth = ({
  year,
  month,
  day,
}: {
  year: number;
  month: number;
  day: number;
}): Date => {
  // month index is from 0 to 11
  return new Date(year, month - 1, day);
};

const getPersonAge = (
  dateOfBirthIngredients: DateOfBirthIngredients,
  fromDate?: Date
): number | undefined => {
  const dob = {
    day: parseDateIngredient(dateOfBirthIngredients.day),
    month: parseDateIngredient(dateOfBirthIngredients.month),
    year: parseDateIngredient(dateOfBirthIngredients.year),
  };

  if (!validateDate(dob)) {
    return undefined;
  }

  const dateOfBirth = getUserDateOfBirth(dob);

  return Math.abs(differenceInYears(dateOfBirth, fromDate || new Date()));
};

const getUser17birthdayDate = (dateOfBirthIngredients: DateOfBirthIngredients): Date => {
  const dob = {
    day: parseDateIngredient(dateOfBirthIngredients.day),
    month: parseDateIngredient(dateOfBirthIngredients.month),
    year: parseDateIngredient(dateOfBirthIngredients.year),
  };

  if (!validateDate(dob)) {
    return new Date();
  }

  return addYears(getUserDateOfBirth(dob), 17);
};

const ALLOWED_UNDERWRITERS = ['Mulsanne'];

const provideAgeBasedOnUnderwriterName = (
  underwriterName?: string | null,
  age?: number
): string | undefined => {
  if (!age || !underwriterName) {
    return undefined;
  }

  return ALLOWED_UNDERWRITERS.includes(underwriterName) ? age.toString() : undefined;
};

const getExactStartDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const utcDate = Date.UTC(year, month, day);
  return {
    year,
    month: month + 1,
    day,
    parsedDate: new Date(utcDate).toISOString(),
  };
};

const isInPast = (
  year: string,
  month: string,
  day: string,
  hours: string,
  minutes: string,
  fromDate?: Date
): boolean => {
  const selectedDate = {
    year: parseDateIngredient(year),
    month: parseDateIngredient(month),
    day: parseDateIngredient(day),
    hours: parseDateIngredient(hours),
    minutes: parseDateIngredient(minutes),
  };

  if (!validateDate(selectedDate)) {
    return false;
  }

  const date = new Date(
    selectedDate.year,
    selectedDate.month - 1,
    selectedDate.day,
    selectedDate.hours,
    selectedDate.minutes
  );

  return isBefore(fromDate || new Date(), date);
};

const valueToTimeOption = (value: number) => {
  return {
    id: value.toString(),
    name: value.toString().padStart(2, '0'),
  };
};

const getNextAvailableStartHourAndMinutes = () => {
  const startTime = getNextSignificantStartTime();

  return {
    hour: valueToTimeOption(startTime.getHours()),
    minutes: valueToTimeOption(startTime.getMinutes()),
  };
};

const isAddOnChecked = (addOn: { checked: boolean }) => addOn.checked;

const uncheckAddOn = (addOn: { checked: boolean }) => ({ ...addOn, checked: false });

const isQuoteStartDateSameAsNextAvilableDate = (quoteStartDateString?: string | null) => {
  if (!quoteStartDateString) {
    return false;
  }

  const quoteStartDate = moment(quoteStartDateString);

  if (!quoteStartDate.isValid()) {
    return false;
  }

  return moment(quoteStartDateString).isSame(getNextSignificantStartTime(), 'minutes');
};

export {
  getPersonAge,
  provideAgeBasedOnUnderwriterName,
  ALLOWED_UNDERWRITERS,
  getUser17birthdayDate,
  getExactStartDate,
  isInPast,
  getNextAvailableStartHourAndMinutes,
  isAddOnChecked,
  uncheckAddOn,
  isQuoteStartDateSameAsNextAvilableDate,
};
