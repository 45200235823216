import { Button, Card, Icon, LoadingSpinner } from '@dayinsure/components';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AddOnType, DayInsureAPI, OpenAPI } from '../../../api';
import usePolicyInfoQuery from '../../../hooks/queries/usePolicyInfoQuery';
import usePerson from '../../../hooks/usePerson';
import useReferrer from '../../../hooks/useReferrer';
import BreakdownNumberCard from '../../Common/PolicyContact/BreakdownNumberCard';
import ClaimsNumberCard from '../../Common/PolicyContact/ClaimNumberCard';
import ContentContainer from '../../Layout/ContentContainer/ContentContainer';
import PageContainer from '../../Layout/PageContainer/PageContainer';
import ConvertGuestDialog from '../GuestAccountConversion/ConvertGuestDialog';
import PurchaseCompleteComponent from './PurchaseCompleteComponent';
import useSignIn from '../../../hooks/useSignIn';

const PurchaseComplete = () => {
  const { t } = useTranslation('quotes');
  const { isFullAccount } = usePerson();
  const referrer = useReferrer();
  const { signIn } = useSignIn();
  const [creatingAccount, setCreatingAccount] = useState(false);

  const { policyId } = useParams();

  const createPolicySession = useCallback(async () => {
    const api = new DayInsureAPI(OpenAPI);

    await api.policy.postPolicyCreateSession(policyId || '').then(response => {
      const sessionId = response.detail as string;
      localStorage.removeItem('LINK-POLICY-TO-ACCOUNT');
      localStorage.setItem('LINK-POLICY-TO-ACCOUNT', sessionId);
    });

    signIn();
  }, [policyId, signIn]);

  const {
    data: policyData,
    isLoading: policyLoading,
    error: policyError,
  } = usePolicyInfoQuery(policyId);

  if (!policyData) {
    return <LoadingSpinner className="my-12" centered />;
  }

  return (
    <PageContainer className="mb-16 w-full md::mb-24">
      <ContentContainer>
        <>
          <h1 id="purchase-success-heading" className="my-8 text-xl text-center sm:mt-12">
            {t('purchase_complete.title')}
          </h1>

          <PurchaseCompleteComponent
            policyId={policyId}
            policy={policyData}
            policyLoading={policyLoading}
            policyError={policyError}
          />

          {/* <AppLinksCard messaging="NEXT_TIME_YOU_BUY" className="mt-8 sm:mt-12" /> */}

          {policyData?.detail?.policy?.underWriter && (
            <ClaimsNumberCard
              underwriter={policyData?.detail?.policy?.underWriter}
              className="mt-8 sm:mt-12"
            />
          )}
          {policyData?.detail?.policy?.selectedAddOns?.find(
            addon =>
              addon.type === AddOnType.BREAKDOWN_ROADSIDE ||
              addon.type === AddOnType.BREAKDOWN_ONWARD_TRAVEL
          ) && <BreakdownNumberCard className="mt-8" />}

          {!isFullAccount && referrer.customerAccessEnabled && (
            <>
              <Card paddingLevel="small" classNames="flex items-center my-8 sm:mt-12">
                <Icon className="text-main-content-1" size="2rem" name="tip" />
                <div className="ml-6 text-main-content-1">
                  <div className="block">
                    <Trans
                      i18nKey="purchase_complete.create_account"
                      t={t}
                      components={[
                        <button
                          type="button"
                          className="font-semibold underline text-link"
                          onClick={() => setCreatingAccount(true)}
                        >
                          Create an account
                        </button>,
                      ]}
                    />
                  </div>
                  <>
                    <div className="block lg:mt-4">
                      <Trans
                        i18nKey="purchase_complete.existing_account"
                        t={t}
                        components={[
                          <button
                            type="button"
                            className="link"
                            id="policy-info-login"
                            onClick={createPolicySession}
                          >
                            Add to an existing account
                          </button>,
                        ]}
                      />
                    </div>
                  </>
                </div>
              </Card>

              <Button
                id="purchase-create-account-btn"
                text={t('purchase_complete.cta')}
                fullWidth
                onClick={() => {
                  setCreatingAccount(true);
                }}
              />
            </>
          )}
        </>
      </ContentContainer>
      <ConvertGuestDialog
        open={creatingAccount}
        onClose={() => {
          setCreatingAccount(false);
        }}
      />
    </PageContainer>
  );
};

export default PurchaseComplete;
