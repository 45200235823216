import type { OpenAPIConfig } from '../core/OpenAPI';

import { AcceptanceCriteriaService } from './services/AcceptanceCriteriaService';
import { AddressService } from './services/AddressService';
import { DocumentService } from './services/DocumentService';
import { OccupationService } from './services/OccupationService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { PaymentSessionService } from './services/PaymentSessionService';
import { PersonService } from './services/PersonService';
import { PolicyService } from './services/PolicyService';
import { PolicySessionService } from './services/PolicySessionService';
import { ProductService } from './services/ProductService';
import { QuoteService } from './services/QuoteService';
import { VehicleService } from './services/VehicleService';
import { BaseAPI } from '../core/BaseAPI';

export class DayInsureAPI extends BaseAPI {
  public readonly acceptanceCriteria: AcceptanceCriteriaService;
  public readonly address: AddressService;
  public readonly document: DocumentService;
  public readonly occupation: OccupationService;
  public readonly paymentMethod: PaymentMethodService;
  public readonly paymentSession: PaymentSessionService;
  public readonly person: PersonService;
  public readonly policy: PolicyService;
  public readonly policySession: PolicySessionService;
  public readonly product: ProductService;
  public readonly quote: QuoteService;
  public readonly vehicle: VehicleService;

  constructor(config?: Partial<OpenAPIConfig>) {
    super(config);

    this.acceptanceCriteria = new AcceptanceCriteriaService(this.request);
    this.address = new AddressService(this.request);
    this.document = new DocumentService(this.request);
    this.occupation = new OccupationService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.paymentSession = new PaymentSessionService(this.request);
    this.person = new PersonService(this.request);
    this.policy = new PolicyService(this.request);
    this.policySession = new PolicySessionService(this.request);
    this.product = new ProductService(this.request);
    this.quote = new QuoteService(this.request);
    this.vehicle = new VehicleService(this.request);
  }
}
