import { AuthProviderProps } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import Referrer from '../types/referrer';
import RouterState from '../types/RouterState';
import { env } from '../env';

export const getAcrValues = (referrer: Referrer, securityId?: string) =>
  `tenant:${referrer.identityProviderName} referrer:${referrer.name} brand:${
    referrer.theme
  } ${securityId ? `securityId:${securityId}` : ''}`;

const useAuthConfig = (referrer: Referrer): AuthProviderProps => {
  const navigate = useNavigate();

  return {
    authority: env.config.AUTH_AUTHORITY || '',
    client_id: env.config.AUTH_CLIENT_ID || '',
    redirect_uri: `${window.origin}/login/redirect`,
    acr_values: getAcrValues(referrer),
    response_type: 'code',
    scope: env.config.AUTH_SCOPE,
    extraTokenParams: {
      acr_values: getAcrValues(referrer),
    },
    onSigninCallback: (user): void => {
      const state = user?.state as RouterState;
      const isReferrerDayinsure = state.referrerName?.toLowerCase() === 'dayinsure';

      if (state.quoteIdForReAccess) {
        // The user has just reauthenticated via the re-access enquiry, take them back to retail
        // in order to determine where to go next
        navigate(
          {
            pathname: '/retail',
            search: `quote=${state.quoteIdForReAccess}&${
              isReferrerDayinsure
                ? `${state.completeQueryParamsFromJourney}`
                : `&referrer=${state.referrerName?.toLowerCase()}&${
                    state.completeQueryParamsFromJourney
                  }`
            }`,
          },
          { replace: true }
        );
      } else {
        // A regular login. Go to either the returnUrl or the dashboard
        navigate(
          {
            pathname: state.returnPath || '/dashboard',
            search: isReferrerDayinsure
              ? `${state.completeQueryParamsFromJourney}`
              : `referrer=${state.referrerName?.toLowerCase()}&${
                  state.completeQueryParamsFromJourney
                }`,
          },
          {
            replace: true,
            state: {
              returnFromSignInRedirect: state.returnPath !== undefined,
              quoteJourneyData: state.quoteJourneyData,
            },
          }
        );
      }
    },
  };
};

export default useAuthConfig;
