import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ApiError, DayInsureAPI, OpenAPI, QuoteIAPIResult } from '../../api';
import GlobalErrorContext from '../../contexts/GlobalErrorContext/GlobalErrorContext';
import query from './queryKeys';
import useNavigateWithReferrer from '../useNavigateWithReferrer';
import blockedReferrers from '../../constants/blockedReferrers';
import useReferrer from '../useReferrer';

const useQuoteQuery = (id: string | undefined) => {
  const referrer = useReferrer();
  const navigate = useNavigateWithReferrer();
  const { t } = useTranslation('quotes');
  const { setGlobalError } = useContext(GlobalErrorContext);

  const getQuote = () => {
    if (!blockedReferrers.includes(referrer.name.toLowerCase())) {
      if (id) {
        const api = new DayInsureAPI(OpenAPI);
        return api.quote.getQuote(id || '');
      }
      throw new Error('No quote ID');
    } else {
      setGlobalError(undefined, {
        title: t('prevent_from_getting_quote_dialog.title'),
        message: t('prevent_from_getting_quote_dialog.p'),
        cta: t('prevent_from_getting_quote_dialog.cta_continue'),
        onClose: () => {
          navigate('/quote?referrer=Dayinsure');
        },
      });
      return undefined;
    }
  };

  return useQuery<QuoteIAPIResult | undefined, ApiError>(
    query.quoteDetails(id || ''),
    getQuote,
    {
      enabled: id !== undefined,
      onError: err => {
        // Don't show a global error on 401. We want to handle this manually.
        if (!(err?.status === 401)) {
          setGlobalError(err);
        }
      },
    }
  );
};

export default useQuoteQuery;
