import type { StringListIAPIResult } from '../models/StringListIAPIResult';
import type { VehicleFuelType } from '../models/VehicleFuelType';
import type { VehicleTransmissionType } from '../models/VehicleTransmissionType';
import type { VehicleType } from '../models/VehicleType';
import type { WebVehicleIAPIResult } from '../models/WebVehicleIAPIResult';
import type { WebVehicleTypeModelsIAPIResult } from '../models/WebVehicleTypeModelsIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class VehicleService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getVehicle(
    vehicleRegistration: string
  ): CancelablePromise<WebVehicleIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleRegistration}',
      path: {
        vehicleRegistration: vehicleRegistration,
      },
    });
  }

  public getVehicleMake(
    vehicleType: VehicleType,
    registrationYear?: number
  ): CancelablePromise<StringListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleType}/make',
      path: {
        vehicleType: vehicleType,
      },
      query: {
        registrationYear: registrationYear,
      },
    });
  }

  public getVehicleMakeModel(
    vehicleType: VehicleType,
    make: string,
    fuelType?: VehicleFuelType,
    transmission?: VehicleTransmissionType,
    registrationYear?: number
  ): CancelablePromise<WebVehicleTypeModelsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleType}/make/{make}/model',
      path: {
        vehicleType: vehicleType,
        make: make,
      },
      query: {
        fuelType: fuelType,
        transmission: transmission,
        registrationYear: registrationYear,
      },
    });
  }
}
