import type { AcceptanceCriteriaResponseListIAPIResult } from '../models/AcceptanceCriteriaResponseListIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { PostAcceptanceCriteriaAgreementRequest } from '../models/PostAcceptanceCriteriaAgreementRequest';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class AcceptanceCriteriaService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getAcceptanceCriteria(
    quoteId?: string
  ): CancelablePromise<AcceptanceCriteriaResponseListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/acceptance-criteria',
      query: {
        quoteId: quoteId,
      },
    });
  }

  public postAcceptanceCriteriaAgreement(
    requestBody?: PostAcceptanceCriteriaAgreementRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/acceptance-criteria-agreement',
      body: requestBody,
    });
  }
}
