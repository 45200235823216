import type { IAPIResult } from '../models/IAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PolicySessionService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public postPolicySessionLinkPolicyToAccount(id: string): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy-session/{id}/link-policy-to-account',
      path: {
        id: id,
      },
    });
  }
}
