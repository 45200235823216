import type { IAPIResult } from '../models/IAPIResult';
import type { PaymentMethodResponseIAPIResult } from '../models/PaymentMethodResponseIAPIResult';
import type { SavePaymentMethodRequest } from '../models/SavePaymentMethodRequest';
import type { SavePaymentMethodResponseIAPIResult } from '../models/SavePaymentMethodResponseIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PaymentMethodService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public postPaymentMethod(
    requestBody?: SavePaymentMethodRequest
  ): CancelablePromise<SavePaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payment-method',
      body: requestBody,
    });
  }

  public getPaymentMethod(
    amount?: string
  ): CancelablePromise<PaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payment-method',
      query: {
        amount: amount,
      },
    });
  }

  public deletePaymentMethod(paymentMethodId: string): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/payment-method/{paymentMethodId}',
      path: {
        paymentMethodId: paymentMethodId,
      },
    });
  }
}
