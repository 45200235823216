import type { OccupationListIAPIResult } from '../models/OccupationListIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class OccupationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getOccupation(
    match?: string,
    take?: number
  ): CancelablePromise<OccupationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/occupation',
      query: {
        match: match,
        take: take,
      },
    });
  }
}
