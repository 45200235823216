import type { ConfirmAccountEmailRequest } from '../models/ConfirmAccountEmailRequest';
import type { ConvertToCustomerRequest } from '../models/ConvertToCustomerRequest';
import type { CreateMarketingPreferenceRequest } from '../models/CreateMarketingPreferenceRequest';
import type { CreatePersonRequest } from '../models/CreatePersonRequest';
import type { CreatePersonResponseIAPIResult } from '../models/CreatePersonResponseIAPIResult';
import type { GetPersonReponseIAPIResult } from '../models/GetPersonReponseIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { LicenceIAPIResult } from '../models/LicenceIAPIResult';
import type { MarketingPreferenceListIAPIResult } from '../models/MarketingPreferenceListIAPIResult';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdatePersonRequest } from '../models/UpdatePersonRequest';
import type { UpdatePersonResponseIAPIResult } from '../models/UpdatePersonResponseIAPIResult';
import type { VerifyEmailAddressRequest } from '../models/VerifyEmailAddressRequest';
import type { WebAssetListIAPIResult } from '../models/WebAssetListIAPIResult';
import type { WebConfirmPasswordResetRequest } from '../models/WebConfirmPasswordResetRequest';
import type { WebPolicySummaryListIAPIResult } from '../models/WebPolicySummaryListIAPIResult';
import type { WebQuoteSummaryListIAPIResult } from '../models/WebQuoteSummaryListIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PersonService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public postPerson(
    requestBody?: CreatePersonRequest
  ): CancelablePromise<CreatePersonResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person',
      body: requestBody,
    });
  }

  public postPersonSendPasswordReset(
    email: string,
    requestBody?: SendPasswordResetRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-password-reset',
      path: {
        email: email,
      },
      body: requestBody,
    });
  }

  public getPerson(id: string): CancelablePromise<GetPersonReponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}',
      path: {
        id: id,
      },
    });
  }

  public putPerson(
    id: string,
    requestBody?: UpdatePersonRequest
  ): CancelablePromise<UpdatePersonResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public getPersonLicence(id: string): CancelablePromise<LicenceIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/licence',
      path: {
        id: id,
      },
    });
  }

  public putPersonChangePassword(
    personId: string,
    requestBody?: PasswordChangeRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{personId}/change-password',
      path: {
        personId: personId,
      },
      body: requestBody,
    });
  }

  public getPersonPolicySummary(
    id: string
  ): CancelablePromise<WebPolicySummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/policy-summary',
      path: {
        id: id,
      },
    });
  }

  public getPersonQuoteSummary(
    id: string,
    take?: number
  ): CancelablePromise<WebQuoteSummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/quote-summary',
      path: {
        id: id,
      },
      query: {
        take: take,
      },
    });
  }

  public getPersonMarketingPreferences(
    id: string
  ): CancelablePromise<MarketingPreferenceListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/marketing-preferences',
      path: {
        id: id,
      },
    });
  }

  public putPersonMarketingPreferences(
    id: string,
    requestBody?: CreateMarketingPreferenceRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}/marketing-preferences',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public getPersonAsset(id: string): CancelablePromise<WebAssetListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/asset',
      path: {
        id: id,
      },
    });
  }

  public postPersonSendEmailVerification(
    email: string,
    requestBody?: SendVerificationEmailRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-email-verification',
      path: {
        email: email,
      },
      body: requestBody,
    });
  }

  public postPersonVerifyEmailAddress(
    id: string,
    requestBody?: VerifyEmailAddressRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{id}/verify-email-address',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public putPersonConvertToCustomer(
    id: string,
    requestBody?: ConvertToCustomerRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}/convert-to-customer',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public postPersonConfirmPasswordReset(
    id: string,
    requestBody?: WebConfirmPasswordResetRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{id}/confirm-password-reset',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public postPersonConfirmAccountEmail(
    personId: string,
    requestBody?: ConfirmAccountEmailRequest
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-account-email',
      path: {
        personId: personId,
      },
      body: requestBody,
    });
  }
}
