import { AssetType, VehicleType, ProductName } from '../../api';
import Referrer from '../../types/referrer';
import { assetTypeToVehicleType } from '../../types/typeMappers';
import { QuoteJourneyFormData } from '../forms/quote';

enum ProductConversionLabel {
  FULL_LICENCE_CAR = 'OU64CL6ZvmUQmv64_AM',
  FULL_LICENCE_VAN = 'xzsNCI2W0GUQmv64_AM',
  FULL_LICENCE_MOTORHOME = 'xzsNCI2W0GUQmv64_AM',
  LEARNER_LICENCE_CAR = '9GcVCObfyGUQmv64_AM',
}

const getProductConversionLabelFromQuote = (
  vehicleType?: VehicleType,
  product?: ProductName
) => {
  if (!vehicleType || !product) return undefined;
  if (
    product === ProductName.LEARNER_OWN_MOTOR ||
    product === ProductName.LEARNER_TOP_UP_MOTOR
  ) {
    return ProductConversionLabel.LEARNER_LICENCE_CAR;
  }
  switch (vehicleType) {
    case VehicleType.VAN_LCV:
      return ProductConversionLabel.FULL_LICENCE_VAN;
    case VehicleType.MOTORHOME:
      return ProductConversionLabel.FULL_LICENCE_MOTORHOME;
    case VehicleType.MOTOR_CAR:
    default:
      return ProductConversionLabel.FULL_LICENCE_CAR;
  }
};

export const customerIdentifiedEvent = (values: QuoteJourneyFormData) => ({
  event: 'customerIdentified',
  customer: {
    email: values.emailAddress || undefined,
    drivingLicenceNumber: values.licenceNumber || undefined,
    drivingLicenceIssuingCountry: 'United Kingdom',
    firstName: values.firstName || undefined,
    lastName: values.surname || undefined,
    dateOfBirth: values.dateOfBirth.parsedDate
      ? Math.floor(new Date(values.dateOfBirth.parsedDate).getTime() / 1000)
      : undefined,
    phone: values.mobileNumber || undefined,
    registrationDate: Math.floor(values.journeyStartTime.getTime() / 1000),
    language: navigator.languages ? navigator.languages[0] : navigator.language,
    address: {
      postcode: values.postcode || undefined,
    },
    occupation: values.occupation?.name || undefined,
  },
});

export const quoteCreatedEvent = (
  referrer: Referrer,
  vehicleType?: VehicleType,
  product?: ProductName,
  price?: number
) => {
  if (!vehicleType || !product || !price) {
    return undefined;
  }
  return {
    event: 'addToCart',
    ecommerce: {
      add: {
        products: [
          {
            id: getProductConversionLabelFromQuote(vehicleType, product),
            name: product,
            category: referrer.name,
            price,
          },
        ],
      },
    },
  };
};

export const quoteCheckoutReachedEvent = (
  referrer: Referrer,
  vehicleType?: VehicleType,
  product?: ProductName,
  price?: number
) => {
  if (!vehicleType || !product || !price) {
    return undefined;
  }

  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        products: [
          {
            id: getProductConversionLabelFromQuote(vehicleType, product),
            name: product,
            category: referrer.name,
            price,
          },
        ],
      },
    },
  };
};

export const productDetailsEvent = (
  referrer: Referrer,
  vehicleType?: VehicleType,
  product?: ProductName,
  price?: number
) => {
  if (!vehicleType || !product || !price) {
    return undefined;
  }

  return {
    event: 'productdetails',
    ecommerce: {
      details: {
        products: [
          {
            id: getProductConversionLabelFromQuote(vehicleType, product),
            name: product,
            category: referrer.name,
            price,
          },
        ],
      },
    },
  };
};

export const transactionEvent = (
  orderId: string,
  referrer: Referrer,
  vehicleType?: AssetType,
  product?: ProductName,
  price?: number
) => {
  if (!vehicleType || !product || !price) {
    return undefined;
  }

  return {
    transactionId: orderId,
    transactionAffiliation: referrer.name,
    transactionTotal: price,
    transactionProducts: [
      {
        sku: getProductConversionLabelFromQuote(
          assetTypeToVehicleType(vehicleType),
          product
        ),
        name: product,
        category: referrer.name,
        price,
        quantity: 1,
      },
    ],
  };
};

export const transactionCompleteEvent = () => ({
  event: 'transactionComplete',
});

export const policyPurchaseEvent = (
  orderId: string,
  referrer: Referrer,
  vehicleType?: AssetType,
  product?: ProductName,
  price?: number
) => {
  if (!vehicleType || !product || !price) {
    return undefined;
  }

  return {
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId,
          revenue: price,
        },
        products: [
          {
            id: getProductConversionLabelFromQuote(
              assetTypeToVehicleType(vehicleType),
              product
            ),
            name: product,
            category: referrer.name,
            price,
            quantity: 1,
          },
        ],
      },
    },
  };
};

export const jauntConversionEvent = {
  event: 'conversion',
  allow_custom_scripts: true,
  send_to: 'DC-12109903/jantconv/jaunt003+standard',
};
