export enum HttpStatusCode {
  CONTINUE = 'Continue',
  SWITCHING_PROTOCOLS = 'SwitchingProtocols',
  PROCESSING = 'Processing',
  EARLY_HINTS = 'EarlyHints',
  OK = 'OK',
  CREATED = 'Created',
  ACCEPTED = 'Accepted',
  NON_AUTHORITATIVE_INFORMATION = 'NonAuthoritativeInformation',
  NO_CONTENT = 'NoContent',
  RESET_CONTENT = 'ResetContent',
  PARTIAL_CONTENT = 'PartialContent',
  MULTI_STATUS = 'MultiStatus',
  ALREADY_REPORTED = 'AlreadyReported',
  IMUSED = 'IMUsed',
  MULTIPLE_CHOICES = 'MultipleChoices',
  AMBIGUOUS = 'Ambiguous',
  MOVED_PERMANENTLY = 'MovedPermanently',
  MOVED = 'Moved',
  FOUND = 'Found',
  REDIRECT = 'Redirect',
  SEE_OTHER = 'SeeOther',
  REDIRECT_METHOD = 'RedirectMethod',
  NOT_MODIFIED = 'NotModified',
  USE_PROXY = 'UseProxy',
  UNUSED = 'Unused',
  TEMPORARY_REDIRECT = 'TemporaryRedirect',
  REDIRECT_KEEP_VERB = 'RedirectKeepVerb',
  PERMANENT_REDIRECT = 'PermanentRedirect',
  BAD_REQUEST = 'BadRequest',
  UNAUTHORIZED = 'Unauthorized',
  PAYMENT_REQUIRED = 'PaymentRequired',
  FORBIDDEN = 'Forbidden',
  NOT_FOUND = 'NotFound',
  METHOD_NOT_ALLOWED = 'MethodNotAllowed',
  NOT_ACCEPTABLE = 'NotAcceptable',
  PROXY_AUTHENTICATION_REQUIRED = 'ProxyAuthenticationRequired',
  REQUEST_TIMEOUT = 'RequestTimeout',
  CONFLICT = 'Conflict',
  GONE = 'Gone',
  LENGTH_REQUIRED = 'LengthRequired',
  PRECONDITION_FAILED = 'PreconditionFailed',
  REQUEST_ENTITY_TOO_LARGE = 'RequestEntityTooLarge',
  REQUEST_URI_TOO_LONG = 'RequestUriTooLong',
  UNSUPPORTED_MEDIA_TYPE = 'UnsupportedMediaType',
  REQUESTED_RANGE_NOT_SATISFIABLE = 'RequestedRangeNotSatisfiable',
  EXPECTATION_FAILED = 'ExpectationFailed',
  MISDIRECTED_REQUEST = 'MisdirectedRequest',
  UNPROCESSABLE_ENTITY = 'UnprocessableEntity',
  LOCKED = 'Locked',
  FAILED_DEPENDENCY = 'FailedDependency',
  UPGRADE_REQUIRED = 'UpgradeRequired',
  PRECONDITION_REQUIRED = 'PreconditionRequired',
  TOO_MANY_REQUESTS = 'TooManyRequests',
  REQUEST_HEADER_FIELDS_TOO_LARGE = 'RequestHeaderFieldsTooLarge',
  UNAVAILABLE_FOR_LEGAL_REASONS = 'UnavailableForLegalReasons',
  INTERNAL_SERVER_ERROR = 'InternalServerError',
  NOT_IMPLEMENTED = 'NotImplemented',
  BAD_GATEWAY = 'BadGateway',
  SERVICE_UNAVAILABLE = 'ServiceUnavailable',
  GATEWAY_TIMEOUT = 'GatewayTimeout',
  HTTP_VERSION_NOT_SUPPORTED = 'HttpVersionNotSupported',
  VARIANT_ALSO_NEGOTIATES = 'VariantAlsoNegotiates',
  INSUFFICIENT_STORAGE = 'InsufficientStorage',
  LOOP_DETECTED = 'LoopDetected',
  NOT_EXTENDED = 'NotExtended',
  NETWORK_AUTHENTICATION_REQUIRED = 'NetworkAuthenticationRequired',
}
