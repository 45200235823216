import { useMutation } from 'react-query';
import { DayInsureAPI, OpenAPI, PostAcceptanceCriteriaAgreementRequest } from '../../api';

const useCreateAcceptanceCriteriaMutation = () => {
  const createAcceptanceCriteria = (
    requestBody: PostAcceptanceCriteriaAgreementRequest
  ) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.acceptanceCriteria.postAcceptanceCriteriaAgreement(requestBody);
  };

  return useMutation(createAcceptanceCriteria);
};

export default useCreateAcceptanceCriteriaMutation;
