import type { ProductResponseIAPIResult } from '../models/ProductResponseIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class ProductService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getProduct(
    productName: string,
    assetType?: string,
    underwriter?: string,
    brandName?: string,
    properties?: Record<string, string>,
    age?: string
  ): CancelablePromise<ProductResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{productName}',
      path: {
        productName: productName,
      },
      query: {
        assetType: assetType,
        underwriter: underwriter,
        brandName: brandName,
        properties: properties,
        age: age,
      },
    });
  }
}
