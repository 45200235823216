import { Dialog, Card, Button, Icon } from '@dayinsure/components';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DayInsureAPI, OpenAPI } from '../../../api';

import {
  getNewPasswordFormInitialData,
  NewPasswordFormData,
  NewPasswordValidationSchema,
} from '../../../helpers/forms/newPassword/NewPasswordForm';
import useNavigateWithReferrer from '../../../hooks/useNavigateWithReferrer';
import usePerson from '../../../hooks/usePerson';
import useReferrer from '../../../hooks/useReferrer';
import { isApiError } from '../../../types/typeguards';
import PasswordValidationComponent from '../../Common/PasswordValidation/PasswordValidationComponent';
import FormPasswordField from '../../FormsFields/FormPasswordField/FormPasswordField';

type CovertGuestDialogProps = {
  open: boolean;
  onClose: () => void;
};

const ConvertGuestDialog = ({ open, onClose }: CovertGuestDialogProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const navigate = useNavigateWithReferrer();
  const { personId, person } = usePerson();
  const referrer = useReferrer();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleClose = (isSubmitting: boolean) => {
    if (!isSubmitting) {
      onClose();
      setError(false);
    }
  };

  const handleSubmit = (
    values: NewPasswordFormData,
    { setSubmitting }: FormikHelpers<NewPasswordFormData>
  ) => {
    if (personId) {
      const api = new DayInsureAPI(OpenAPI);
      api.person
        .putPersonConvertToCustomer(personId, {
          password: values.password,
          confirmationUrl: `${window.origin}/sign-up/verify`,
          referrer: referrer.name,
          identityProvider: referrer.identityProviderName,
        })
        .then(() => {
          navigate(
            {
              pathname: `/sign-up/link-sent`,
            },
            { state: { email: person?.email, id: personId } }
          );
        })
        .catch(err => {
          setError(true);
          if (isApiError(err)) {
            setErrorMessage(err.body.messages?.[0]?.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setError(true);
      setSubmitting(false);
    }
  };

  return (
    <Formik<NewPasswordFormData>
      initialValues={getNewPasswordFormInitialData()}
      validationSchema={NewPasswordValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, isValid, dirty }) => (
        <Dialog
          open={open}
          onClose={() => {
            handleClose(isSubmitting);
          }}
          title={
            error ? t('auth:convert_guest.error_title') : t('auth:convert_guest.title')
          }
        >
          {!error ? (
            <>
              <Form>
                <FormPasswordField
                  id="convert-guest-password"
                  name="password"
                  label={{ icon: 'lock', text: t('auth:convert_guest.password_label') }}
                  placeholder={t('auth:convert_guest.password_placeholder')}
                  withErrors={false}
                  spacingClassName="mb-8"
                  ariaLabel={t('common:reveal_password')}
                  variant="dialog"
                />
                <FormPasswordField
                  id="convert-guest-confirm-password"
                  name="confirmPassword"
                  label={{
                    icon: 'lock',
                    text: t('auth:convert_guest.confirm_password_label'),
                  }}
                  placeholder={t('auth:convert_guest.confirm_password_placeholder')}
                  withErrors={false}
                  spacingClassName="mb-8"
                  ariaLabel={t('common:reveal_password')}
                  variant="dialog"
                />

                <PasswordValidationComponent
                  password={values.password}
                  confirmPassword={values.confirmPassword}
                  variant="dialog"
                />

                <Button
                  id="convert-guest-create-account"
                  fullWidth
                  submit
                  text={t('auth:convert_guest.cta')}
                  classNames="mt-6 md:mt-8"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                />
                <Button
                  id="convert-guest-cancel"
                  fullWidth
                  onClick={() => {
                    handleClose(isSubmitting);
                  }}
                  text={t('auth:convert_guest.close')}
                  classNames="mt-6 md:mt-8"
                  variant="dialogSecondary"
                  disabled={isSubmitting}
                />
              </Form>
            </>
          ) : (
            <>
              <Card
                classNames="!bg-popup-background flex items-center gap-4 md:gap-6 text-left"
                paddingLevel="small"
              >
                <Icon name="warning" size="2rem" className="text-popup-link" />
                <span id="dialog-error-message">
                  {errorMessage || t('auth:convert_guest.error_fallback')}
                </span>
              </Card>
              <Button
                id="convert-guest-error-cta"
                fullWidth
                onClick={onClose}
                text={t('auth:convert_guest.error_cta')}
                classNames="mt-6 md:mt-8"
              />
            </>
          )}
        </Dialog>
      )}
    </Formik>
  );
};

export default ConvertGuestDialog;
