import type { ObjectIServiceResult } from '../models/ObjectIServiceResult';
import type { PaymentSessionRequest } from '../models/PaymentSessionRequest';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PaymentSessionService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public postPaymentSession(
    requestBody?: PaymentSessionRequest
  ): CancelablePromise<ObjectIServiceResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payment-session',
      body: requestBody,
    });
  }
}
