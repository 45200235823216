import type { CreateQuoteRequest } from '../models/CreateQuoteRequest';
import type { PolicyResponseIAPIResult } from '../models/PolicyResponseIAPIResult';
import type { QuoteAuthDetailsIAPIResult } from '../models/QuoteAuthDetailsIAPIResult';
import type { QuoteIAPIResult } from '../models/QuoteIAPIResult';
import type { QuoteSummaryResponseIAPIResult } from '../models/QuoteSummaryResponseIAPIResult';
import type { ResumePurchaseQuoteRequest } from '../models/ResumePurchaseQuoteRequest';
import type { UpdateQuoteRequest } from '../models/UpdateQuoteRequest';
import type { WebDocumentListIAPIResult } from '../models/WebDocumentListIAPIResult';
import type { WebPurchaseQuoteRequest } from '../models/WebPurchaseQuoteRequest';
import type { WebPurchaseQuoteResponseIAPIResult } from '../models/WebPurchaseQuoteResponseIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class QuoteService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public postQuote(
    requestBody?: CreateQuoteRequest
  ): CancelablePromise<QuoteSummaryResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote',
      body: requestBody,
    });
  }

  public getQuote(id: string): CancelablePromise<QuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}',
      path: {
        id: id,
      },
    });
  }

  public putQuote(
    id: string,
    requestBody?: UpdateQuoteRequest
  ): CancelablePromise<QuoteSummaryResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/quote/{id}',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public postQuotePurchase(
    id: string,
    requestBody?: WebPurchaseQuoteRequest
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote/{id}/purchase',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public postQuoteResumePurchase(
    id: string,
    requestBody?: ResumePurchaseQuoteRequest
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote/{id}/resume-purchase',
      path: {
        id: id,
      },
      body: requestBody,
    });
  }

  public getQuotePolicy(id: string): CancelablePromise<PolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/policy',
      path: {
        id: id,
      },
    });
  }

  public getQuoteAuthDetails(id: string): CancelablePromise<QuoteAuthDetailsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/auth-details',
      path: {
        id: id,
      },
    });
  }

  public getQuoteDocuments(id: string): CancelablePromise<WebDocumentListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/documents',
      path: {
        id: id,
      },
    });
  }
}
