import type { WebDocumentIAPIResult } from '../models/WebDocumentIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class DocumentService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getDocument(
    documentType: string,
    underwriter?: string,
    product?: string,
    referrer?: string,
    assetType?: string
  ): CancelablePromise<WebDocumentIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/document/{documentType}',
      path: {
        documentType: documentType,
      },
      query: {
        underwriter: underwriter,
        product: product,
        referrer: referrer,
        assetType: assetType,
      },
    });
  }
}
