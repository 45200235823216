import { useContext } from 'react';
import { useQuery } from 'react-query';
import { ApiError, DayInsureAPI, OpenAPI, WebDocumentListIAPIResult } from '../../api';
import GlobalErrorContext from '../../contexts/GlobalErrorContext/GlobalErrorContext';
import query from './queryKeys';

const useQuoteDocumentQuery = (id: string | undefined) => {
  const { setGlobalError } = useContext(GlobalErrorContext);
  const getQuoteDocuments = () => {
    if (id) {
      const api = new DayInsureAPI(OpenAPI);
      return api.quote.getQuoteDocuments(id || '');
    }
    throw new Error('No quote ID');
  };

  return useQuery<WebDocumentListIAPIResult, ApiError>(
    query.quoteDocuments(id || ''),
    getQuoteDocuments,
    {
      refetchOnMount: true,
      enabled: id !== undefined,
      onError: err => {
        // Don't show a global error on 401. We want to handle this manually.
        if (!(err?.status === 401)) {
          setGlobalError(err);
        }
      },
    }
  );
};

export default useQuoteDocumentQuery;
