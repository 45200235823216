import type { WebApiAddressIAPIResult } from '../models/WebApiAddressIAPIResult';
import type { WebApiAddressWithPostkeyListIAPIResult } from '../models/WebApiAddressWithPostkeyListIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class AddressService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getAddress(
    postcode: string,
    houseNameOrNumber?: string
  ): CancelablePromise<WebApiAddressWithPostkeyListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/address/{postcode}',
      path: {
        postcode: postcode,
      },
      query: {
        houseNameOrNumber: houseNameOrNumber,
      },
    });
  }

  public getAddressDetail(postkey: string): CancelablePromise<WebApiAddressIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/address/{postkey}/detail',
      path: {
        postkey: postkey,
      },
    });
  }
}
