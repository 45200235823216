import type { AmendmentQuoteResponseIAPIResult } from '../models/AmendmentQuoteResponseIAPIResult';
import type { CancellationQuoteIAPIResult } from '../models/CancellationQuoteIAPIResult';
import type { CancelPolicyRequest } from '../models/CancelPolicyRequest';
import type { DurationListIAPIResult } from '../models/DurationListIAPIResult';
import type { GetPolicyDocumentResponseIAPIResult } from '../models/GetPolicyDocumentResponseIAPIResult';
import type { GetPolicyDocumentsResponseIListIAPIResult } from '../models/GetPolicyDocumentsResponseIListIAPIResult';
import type { GetPolicyResponseIAPIResult } from '../models/GetPolicyResponseIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { QuoteAuthDetailsIAPIResult } from '../models/QuoteAuthDetailsIAPIResult';
import type { ResumePurchaseAmendmentQuoteRequest } from '../models/ResumePurchaseAmendmentQuoteRequest';
import type { StringIServiceResult } from '../models/StringIServiceResult';
import type { WebCancelPolicyResponseIAPIResult } from '../models/WebCancelPolicyResponseIAPIResult';
import type { WebCreateAmendQuoteRequest } from '../models/WebCreateAmendQuoteRequest';
import type { WebPurchaseQuoteRequest } from '../models/WebPurchaseQuoteRequest';
import type { WebPurchaseQuoteResponseIAPIResult } from '../models/WebPurchaseQuoteResponseIAPIResult';

import type { CancelablePromise } from '../../core/CancelablePromise';
import type { BaseHttpRequest } from '../../core/BaseHttpRequest';

export class PolicyService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  public getPolicy(
    id: string,
    policyVersion?: string
  ): CancelablePromise<GetPolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{id}',
      path: {
        id: id,
      },
      query: {
        policyVersion: policyVersion,
      },
    });
  }

  public getPolicyDocument(
    id: string
  ): CancelablePromise<GetPolicyDocumentsResponseIListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{id}/document',
      path: {
        id: id,
      },
    });
  }

  public getPolicyDocumentByType(
    policyId: string,
    documentType: string
  ): CancelablePromise<GetPolicyDocumentResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/document/{documentType}',
      path: {
        policyId: policyId,
        documentType: documentType,
      },
    });
  }

  public postPolicyDocumentSendPaperCopy(
    policyId: string,
    documentType: string
  ): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/document/{documentType}/send-paper-copy',
      path: {
        policyId: policyId,
        documentType: documentType,
      },
    });
  }

  public postPolicyCancellationQuote(
    policyId: string
  ): CancelablePromise<CancellationQuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/cancellation-quote',
      path: {
        policyId: policyId,
      },
    });
  }

  public postPolicyCancellationQuoteCancelPolicy(
    policyId: string,
    cancellationQuoteId: string,
    requestBody?: CancelPolicyRequest
  ): CancelablePromise<WebCancelPolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/cancellation-quote/{cancellationQuoteId}/cancel-policy',
      path: {
        policyId: policyId,
        cancellationQuoteId: cancellationQuoteId,
      },
      body: requestBody,
    });
  }

  public postPolicyAmendmentQuote(
    policyId: string,
    requestBody?: WebCreateAmendQuoteRequest
  ): CancelablePromise<AmendmentQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/amendment-quote',
      path: {
        policyId: policyId,
      },
      body: requestBody,
    });
  }

  public getPolicyAmendmentQuote(
    policyId: string,
    amendmentQuoteId: string
  ): CancelablePromise<AmendmentQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/amendment-quote/{amendmentQuoteId}',
      path: {
        policyId: policyId,
        amendmentQuoteId: amendmentQuoteId,
      },
    });
  }

  public postPolicyAmendmentQuotePurchase(
    policyId: string,
    amendmentQuoteId: string,
    requestBody?: WebPurchaseQuoteRequest
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/amendment-quote/{amendmentQuoteId}/purchase',
      path: {
        policyId: policyId,
        amendmentQuoteId: amendmentQuoteId,
      },
      body: requestBody,
    });
  }

  public postPolicyAmendmentQuoteResumePurchase(
    policyId: string,
    amendmentQuoteId: string,
    requestBody?: ResumePurchaseAmendmentQuoteRequest
  ): CancelablePromise<WebPurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/amendment-quote/{amendmentQuoteId}/resume-purchase',
      path: {
        policyId: policyId,
        amendmentQuoteId: amendmentQuoteId,
      },
      body: requestBody,
    });
  }

  public getPolicyExtensionDuration(
    policyId: string
  ): CancelablePromise<DurationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/extension-duration',
      path: {
        policyId: policyId,
      },
    });
  }

  public postPolicyCreateSession(
    policyId: string
  ): CancelablePromise<StringIServiceResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/create-session',
      path: {
        policyId: policyId,
      },
    });
  }

  public getPolicyAuthDetails(id: string): CancelablePromise<QuoteAuthDetailsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{id}/auth-details',
      path: {
        id: id,
      },
    });
  }
}
