import { useTranslation } from 'react-i18next';
import { LoadingSpinner } from '@dayinsure/components';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import ErrorDialog from '../../../Common/ErrorDialog/ErrorDialog';
import PageContainer from '../../../Layout/PageContainer/PageContainer';
import cup from '../../../../assets/cup.png';
import { DayInsureAPI, OpenAPI } from '../../../../api';
import { QuoteJourneyRoutes } from '../../../../helpers/forms/quote';
import useNavigateWithReferrer from '../../../../hooks/useNavigateWithReferrer';
import useEffectOnce from '../../../../hooks/useEffectOnce';

interface State {
  id: string;
}

const PaymentProcessing = () => {
  const { t } = useTranslation('quotes');
  const navigate = useNavigateWithReferrer();
  const location = useLocation();
  const state = location.state as State;
  const quoteId = state?.id;
  const [processingErrorDialog, setPaymentErrorDialog] = useState(false);

  const handlePaymentProcessingSuccess = (policyId: string) => {
    navigate(
      {
        pathname: `/policy/${policyId}/${QuoteJourneyRoutes.PaymentSuccess}`,
      },
      { replace: true }
    );
  };

  const getPolicy = (retryCount: number, pollingInterval: number) => {
    const api = new DayInsureAPI(OpenAPI);
    api.quote
      .getQuotePolicy(quoteId)
      .then(data => {
        if (data.detail?.policy?.id) {
          handlePaymentProcessingSuccess(data.detail.policy.id);
        }
      })
      .catch(() => {
        if (retryCount < 6 && pollingInterval) {
          setTimeout(() => {
            getPolicy(retryCount + 1, pollingInterval * 2);
          }, pollingInterval);
        } else {
          setPaymentErrorDialog(true);
        }
      });
  };

  useEffectOnce(() => {
    if (quoteId) {
      getPolicy(0, 1000);
    } else {
      navigate({ pathname: '/quote' });
    }
  });

  return (
    <PageContainer className={clsx('transition-opacity duration-300')}>
      <div className="flex flex-col mt-8 mb-16 w-full sm:mt-12 md:mb-24 text-main-content-1">
        <h1
          className="self-center mb-6 text-xl text-center sm:mb-12"
          id="payment-processing-heading"
        >
          {t('payment.processing.title')}
        </h1>
        <img src={cup} alt="cup" className="mx-auto h-60" />
        <h2 className="self-center mb-8 text-lg text-center">
          {t('payment.processing.subtitle')}
        </h2>
        <LoadingSpinner size="regular" centered />
      </div>
      <ErrorDialog
        open={processingErrorDialog}
        onClose={() => {
          navigate({
            pathname: `/quote`,
          });
        }}
        title={t('payment.pollingError.title')}
        error={t('payment.pollingError.error')}
        ctaId="payment-processing-error"
        ctaMessage={t('payment.pollingError.close')}
      />
    </PageContainer>
  );
};

export default PaymentProcessing;
